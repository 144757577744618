<template>
  <ul class="navbar-nav">
    <li class="nav-item" v-bind:class="{ active: $route.name == 'people' }" v-if="is_superuser">
      <router-link :to="{ name: 'people' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="9" cy="7" r="4" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 11l2 2l4 -4" /></svg>
        </span>
        <span class="nav-link-title"> Избиратели</span>
      </router-link>
    </li>
    <li class="nav-item" v-bind:class="{ active: $route.name == 'reports' }">
      <router-link :to="{ name: 'reports' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="9" y1="17" x2="9" y2="12" /><line x1="12" y1="17" x2="12" y2="16" /><line x1="15" y1="17" x2="15" y2="14" /></svg>
        </span>
        <span class="nav-link-title"> Явка </span>
      </router-link>
    </li>

  </ul>
</template>




<script>
export default {
  name: "Topmenu",
  data: () => ({
    is_superuser: localStorage.getItem("is_superuser") == 'true',
  }),
  props: {},
};
</script>