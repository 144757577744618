<template>
<div class="card">
  <div class="card-header">
    <h3 class="card-title">Поиск</h3>
  </div>
  <div class="card-body">
    <form v-on:keyup.enter="search" >
      <div class="form-group mb-3">
        <label class="form-label">Фамилия</label>
        <div>
          <input type="text" class="form-control" v-model="searchForm.first_name">
        </div>
      </div>
      <div class="form-group mb-3">
        <label class="form-label">Имя</label>
        <div>
          <input type="text" class="form-control" v-model="searchForm.name">
        </div>
      </div>
      <div class="form-group mb-3">
        <label class="form-label">Улица</label>
        <div>
          <input type="text" class="form-control" v-model="searchForm.street">
        </div>
      </div>
      <div class="form-group mb-3">
        <label class="form-label">УИК</label>
        <div>
          <input type="text" class="form-control" v-model="searchForm.uik">
        </div>
      </div>
      <div class="form-group mb-3 ">
        <label class="form-label">Организация</label>
        <select class="form-select" v-model="searchForm.org">
          <option v-for="org in orgs" :value="org.id" :key="org.id">
            {{ org.name }}
          </option>
        </select>
      </div>
      <div class="form-group mb-3 ">
        <label class="form-label">Зам. Главы</label>
        <select class="form-select" v-model="searchForm.zam">
          <option v-for="zam in zams" :value="zam.id" :key="zam.id">
            {{ zam.fio }}
          </option>
        </select>
      </div>
      <div class="form-group mb-3 ">
        <label class="form-label">Явка</label>
        <select class="form-select" v-model="searchForm.visited">
          <option value="1">Проголосовал</option>
          <option value="0">Не проголосовал</option>
        </select>
      </div>
      <div class="form-footer">
        <button type="button" class="btn btn-primary" @click="search()">
          <i class="fa fa-search"></i> Найти
        </button>
        <button type="button" class="btn btn-default pull-right" @click="resetForm()">
          Очистить
        </button>
      </div>
    </form>
  </div>
</div>
</template>


<script>
export default {
  name: 'SearchPanel',
  components: {
  },
  data: function () {
    return {
      searchForm: {},
      orgs: [],
      zams: [],
    };
  },
  mounted() {
    fetch("/uik/orgs/list/")
      .then((response) => response.json())
      .then((data) => {
        this.orgs = data.results;
      });
    fetch("/uik/zam/list/")
      .then((response) => response.json())
      .then((data) => {
        this.zams = data.results;
      });
  },
  methods: {
    search() {
      this.$emit('searchFormFilter', this.searchForm);
    },
    resetForm(){
      this.searchForm = {};
      this.search();
    }
  }
}
</script>